var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.userRepository
    ? _c(
        "vs-select",
        _vm._g(
          _vm._b(
            {
              ref: "select",
              attrs: {
                placeholder: _vm.$t("selecione-usuarios-para-filtrar"),
                multiple: "",
                autocomplete: "",
                label: _vm.$t("Users"),
                noData:
                  "Insira algum questionário para carregar a lista de usuários.",
              },
            },
            "vs-select",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        ),
        _vm._l(_vm.userRepository.objects, function (item, index) {
          return _c("vs-select-item", {
            key: index,
            attrs: {
              value: item.id,
              text:
                item.name +
                " <br/><div class='text-sm'>" +
                item.email +
                "<div>",
            },
          })
        }),
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }