
<template>
  <fieldset class="p-2 pt-0 border border-grey">
    <legend class="px-1 font-bol"> Filtros </legend>
    <div class="flex items-center p-2 flex-wrap gap-4">
      <select-suggestion
        :key="questionnaire_id"
        :class="`flex-grow w-full ${invalidQuestionnaire ? 'required' : ''}`"
        model="ContentQuestionnaire"
        label="Questionário"
        :max="20"
        :itemTextFormat="
        (model) => _.get(model, 'name', _.get(model, 'title', 'UNK'))
        "
        color="dark"
        v-model="values.questionnaire"
        :appendClearOption="true"
        ref="select_questionnaire"
        promptText="Informe o nome para pesquisar"
        placeholderText="Digite o nome do questionário"
        @clearSelection="clearFilters"
      />

      <div class="flex-grow">
        <user-repository
          class="w-full"
          :userRepository="userRepository"
          v-model="values.user"
        />
      </div>

      <div class="grid">
        <label class="col-span-3 p- font-medium text-grey"> CRITICIDADE DOS EVENTOS </label>
        <div class="flex gap-2 flex-grow-0 select-none">
          <div @click="filterSeverity('L')" :class="severityFilterVisuals('L', 'black')">
            <font-awesome-icon icon="exclamation" class="h-4 w-4 text-success"/>
            <span class="text-success">Baixa</span>
          </div>
          <div @click="filterSeverity('M')" :class="severityFilterVisuals('M', 'black')">
            <font-awesome-icon icon="exclamation-circle" class="h-4 w-4 text-warning"/>
            <span class="text-warning">Média</span>
          </div>
          <div @click="filterSeverity('H')" :class="severityFilterVisuals('H', 'black')">
            <font-awesome-icon icon="exclamation-triangle" class="h-4 w-4 text-danger"/>
            <span class="text-danger">Alta</span>
          </div>
        </div>
      </div>
      <div class="flex gap-1 ml-auto">
        <vs-checkbox class="text-sm smaller-checkbox" v-model="values.autoFilter">Automático</vs-checkbox>
        <vs-button :disabled="!values.questionnaire" class="py-auto px-4" @click="emitFetch">Filtrar</vs-button>
        <vs-button class="py-auto px-4" type="border" :disabled="!isFiltering" @click="clearFilters">Limpar</vs-button>
      </div>
    </div>

  </fieldset>
</template>

<script>
import SelectSuggestion from '@/components/SelectSuggestion.vue'
import ContentQuestionnaireService from '@/services/api/ContentQuestionnaireService'
import ObjectRepository from '@/models/ObjectRepository'
import UserRepository from '@/components/UserRepository.vue'

export default {
  components: { SelectSuggestion, UserRepository },

  props: {
    values: {
      type: Object,
      default: () => ({
        questionnaire: null,
        user: [],
        severity: null,
        autoFilter: false,
      })
    }
  },

  model: {
    event: 'input',
    prop: 'values'
  },

  data: () => ({
    // values: {
    //   start_at: null,
    //   end_at: null,
    //   config: null,
    //   user: null,
    //   severity: null,
    //   autoFilter: false,
    // },
    questionnaireService: null,
    autoFilterBounceTimer: null,
    userRepository: null,
  }),

  computed: {
    isFiltering() {
      return (
        this.values.user ||
        this.values.severity ||
        this.values.questionnaire
      )
    },
    questionnaire_id() {
      return this._.get(this.values.questionnaire, 'id', null)
    },
    hasUserOptions() {
      const users = this.userRepository.objects

      return users.length > 0
    }
  },

  watch: {
    'values.config'(val) {
      this.emitFetch()
    },
    'values.user'(val) {
      this.debouncedReload()
    },
    'values.severity'(val) {
      this.debouncedReload()
    },
    'values.autoFilter'(val) {
      if (val && this.isFiltering){
        this.emitFetch()
      }else{
        this.shutdownAutoload()
      }
    },

    'values.questionnaire'(questionnaire) {
      this.$emit('questionnaireInput')
      if (!questionnaire) {
        this.userRepository.clearObjects()
        return
      }
      const questionnaires = [questionnaire]
      if (this.questionnaireService && questionnaires && this._.isArray(questionnaires) && questionnaires.length > 0) {
        this.$vs.loading()

        /**
         * After a questionnaire is added or removed, we need to update the user select listing
         * and get these questionnaire's data to cache it in the store.
         *
         * For that we push every request, since there can be more than one questionnaire selected,
         * to an array then solve all of them at once, to guarantee that all are resolved
         * and done before going through each of their results.
         */
        const participantRequests = []
        const dataRequests = []

        questionnaires.forEach((questionnaire) => {
          participantRequests.push(this.questionnaireService.getParticipants(questionnaire.id, 3))
          dataRequests.push(this.questionnaireService.getContentSimple(questionnaire.id))
        })

        Promise.all(participantRequests).then(
          (results) => {
            const users = []

            results.forEach((participants) => {
              participants.forEach((participant) => {
                // Do not add user to the array if it was already added
                const exists = users.findIndex((user) => user.id === participant.id) !== -1
                if (!exists) users.push(participant)
              })
            })

            this.userRepository.setObjects(users)
            this.userRepository.sortObjects('name')
            this.$vs.loading.close()
          }
        )
      } else {
        this.userRepository.clearObjects()
      }
    }
  },

  methods: {
    filterSeverity(severity) {
      if (this.values.severity === severity) this.values.severity = null
      else this.values.severity = severity
    },
    emitFetch() {
      this.$emit('fetch', this.values)
    },
    shutdownAutoload(){
      this.$emit('shutdownAUTOLOAD', this.values)
    },
    updateFilters() {
      this.$emit('input', this.values)
    },
    severityFilterVisuals(filter, color = 'primary', extra = 'flex px-2 items-center cursor-pointer gap-1') {
      const borderDefinition = `border-${color} border-little-round border-shadowed`
      const colorized = this.values.severity === filter ? `stroke-current bg-${color} ${borderDefinition}` : ''
      return [
        colorized,
        extra
      ]
    },
    debouncedReload() {
      if (this.values.autoFilter) {
        if (this.autoFilterBounceTimer) clearTimeout(this.autoFilterBounceTimer)
        this.autoFilterBounceTimer = setTimeout(this.emitFetch, 500)
      }
    },
    clearFilters() {
      this.values.user = []
      this.values.severity = null
      this.userRepository.clearObjects()
      clearTimeout(this.autoFilterBounceTimer)
      // this.values.questionnaire = null
      this.$refs['select_questionnaire'].reset()
      this.$emit('clearFilters')
    },
  },

  beforeMount() {
    this.questionnaireService = ContentQuestionnaireService.build(this.$vs)
    this.userRepository = new ObjectRepository()
    this.userRepository.suggestableProperties = ['name', 'email']
  }

}
</script>

<style>

</style>
