<template>
  <div>
    <e-charts v-if="mergedData" autoresize :options="mergedData" ref="graph" />
  </div>
</template>

<script>
import ECharts from 'vue-echarts/components/ECharts'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/title'
import 'echarts/lib/chart/line'

export default {
  components: { ECharts },
  props: {
    data: {
      type: Object,
      default: null
    }
  },

  computed: {
    mergedData() {
      const data = this.data
      return {
        ...data,
        tooltip: {
          trigger: 'axis',
          position: function (pt) {
            return [pt[0], '0%']
          }
        },
        xAxis: {
          axisLabel: {
            formatter: (value) => {
              const hour = this.$moment(value).format('HH:mm')
              const date = this.$moment(value).format('DD-MM-YY')
              return `${hour} \n ${date}`
            }
          },
          ...data.xAxis
        }
        
      }
    }
  }
}
</script>

<style>

</style>