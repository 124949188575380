<template>
  <vs-select
    v-if="userRepository"
    ref="select"
    :placeholder="$t('selecione-usuarios-para-filtrar')"
    multiple
    autocomplete
    :label="$t('Users')"
    v-bind="$attrs"
    v-on="$listeners"
    noData="Insira algum questionário para carregar a lista de usuários."
  >
    <vs-select-item :key="index" :value="item.id" :text="`${item.name} <br/><div class=\'text-sm\'>${item.email}<div>`" v-for="(item,index) in userRepository.objects" />
  </vs-select>
</template>

<script>
export default {
  props: {
    userRepository: {
      type: Object,
      default: null
    }
  },
  inheritAttrs: false,
}
</script>

<style>

</style>