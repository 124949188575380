var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "grid grid-cols-2 gap-4" }, [
    _c("div", { staticClass: "_indicator-category" }, [
      _vm._m(0),
      _c("div", [
        _c("div", { staticClass: "_indicator-block" }, [
          _c("span", [_vm._v(" " + _vm._s(_vm.scheduledCount) + " ")]),
          _c("label", [_vm._v(" Previsto ")]),
        ]),
        _c("div", { staticClass: "_indicator-block" }, [
          _c("span", [_vm._v(" " + _vm._s(_vm.inProgressCount) + " ")]),
          _c("label", [_vm._v(" Não Finalizado ")]),
        ]),
        _c("div", { staticClass: "_indicator-block" }, [
          _c("span", [_vm._v(" " + _vm._s(_vm.finishedCount) + " ")]),
          _c("label", [_vm._v(" Finalizados ")]),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "_indicator-category" }, [
      _vm._m(1),
      _c("div", [
        _c("div", { staticClass: "_indicator-block" }, [
          _c("span", [_vm._v(" " + _vm._s(_vm.eventsCount) + " ")]),
          _c("label", [_vm._v(" Total ")]),
        ]),
        _c("div", { staticClass: "flex flex-row gap-2" }, [
          _c("div", { staticClass: "_indicator-block" }, [
            _c("span", [_vm._v(" " + _vm._s(_vm.lowSeverityCount) + " ")]),
            _c("label", { staticClass: "text-success" }, [_vm._v(" Baixo ")]),
          ]),
          _c("div", { staticClass: "_indicator-block" }, [
            _c("span", [_vm._v(" " + _vm._s(_vm.mediumSeverityCount) + " ")]),
            _c("label", { staticClass: "text-warning" }, [_vm._v(" Médio ")]),
          ]),
          _c("div", { staticClass: "_indicator-block" }, [
            _c("span", [_vm._v(" " + _vm._s(_vm.highSeverityCount) + " ")]),
            _c("label", { staticClass: "text-danger" }, [_vm._v(" Alto ")]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("label", [_vm._v(" Questionário ")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("label", [_vm._v(" Eventos ")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }